import { ScenarioShortNames } from './aides/aideRenovationDAmpleurDefs';
import { InstallationPV } from './installationPV';
import { Categorized, ProduitIcoll, isKnownCategories, isKnownParentCategories, isKnownRootCategories } from './products';
import { SubThemeType, isComplexThemeType } from './theme';

type MinimalPackageData = {
    /** NE PAS UTILISER POUR DE LA LOGIQUE METIER LOCALE */
    themeLb: SubThemeType | string;
    reference: string;
    mainProduct: Categorized;
};

type BasePackageData = MinimalPackageData & {
    /**
     * Un uuid technique local pour le package (différent de icollId)
     */
    uuid: string;
    themeId: string;

    title: string;

    /**
     * Le nom du scénario lorsqu'on propose ce package pour la rénovation d'ampleur.
     */
    scenarioNameForRA?: ScenarioShortNames | undefined;

    /**
     * L'id icoll du package (différent de uuid)
     */
    icollId: string;
    applicable: boolean;

    mainProduct: ProduitIcoll;
    poseProduct?: ProduitIcoll;

    puissance?: number;

    priceHt: number;
    priceTtc: number;
    /**
     * L'aide doit être négative.
     */
    totalHelp: number;
    /**
     * priceHt + totalHelp (puisque l'aide est négative)
     */
    totalHelpDeduced: number;
};

export type PackageData_PaneauPhotovoltaique = BasePackageData & {
    /** NE PAS UTILISER POUR DE LA LOGIQUE METIER LOCALE */
    themeLb: "Economie d'énergie/Photovoltaïque";

    /** installation PV pour tous les calculs PV.*/
    installationPV: InstallationPV;
    /** aide potentielle, téléchargée une fois de l'api, et stockée (au lieu de la télécharger 100 fois par chargement d'écran.) */
    potentialAideInvestissement: number;
};

export type PackageData_MMA = BasePackageData & {
    /** NE PAS UTILISER POUR DE LA LOGIQUE METIER LOCALE */
    themeLb: 'Services/Garantie de revenu solaire';
    mma: true;
};
export type PackageData = BasePackageData | PackageData_PaneauPhotovoltaique | PackageData_MMA;

export type SomethingThatCanBePV = Categorized | MinimalPackageData | PackageData | icollPackageOutput | icollProductOutput | Record<string, any>;
export type SomethingThatCanBeBattery = Categorized | MinimalPackageData | PackageData | icollPackageOutput | icollProductOutput | Record<string, any>;

const isCategorized = (candidate: any): candidate is Categorized => {
    if (!candidate.breadcrumb) return false;
    if (!candidate.categorie_racine) return false;
    if (!candidate.categorie_parent) return false;
    if (!candidate.categorie) return false;

    if (typeof candidate.breadcrumb !== 'string') return false;
    if (typeof candidate.categorie_racine !== 'string') return false;
    if (typeof candidate.categorie_parent !== 'string') return false;
    if (typeof candidate.categorie !== 'string') return false;

    return true;
};

const isMinimalPackageData = (candidate: any): candidate is MinimalPackageData => {
    if (!candidate.themeLb || !candidate.reference || !candidate.mainProduct) return false;
    if (!isComplexThemeType(candidate.themeLb)) return false;
    if (!isCategorized(candidate.mainProduct)) return false;
    return true;
};

export const isPV = (candidate: SomethingThatCanBePV): boolean => {
    if (isCategorized(candidate)) return categorizedIsPV(candidate);

    if (isMinimalPackageData(candidate)) return packIsPV(candidate);

    if (Array.isArray(candidate.produits)) {
        for (const p of candidate.produits) {
            if (isCategorized(p) && categorizedIsPV(p)) return true;
        }
    }
    return false;
};

export const packIsPV = (pack: PackageData | MinimalPackageData): pack is PackageData_PaneauPhotovoltaique => {
    if (!isComplexThemeType(pack.themeLb)) return false;
    if (!pack.themeLb.includes('Photovoltaïque')) return false;
    return categorizedIsPV(pack.mainProduct);
};

export const packIsMMA = (pack: PackageData | MinimalPackageData): pack is PackageData_MMA => {
    if (!isComplexThemeType(pack.themeLb)) {
        return false;
    }
    if (!pack.themeLb.includes('Garantie de revenu solaire')) {
        return false;
    }
    return categorizedIsMMA(pack.mainProduct);
};

export const packIsBattery = (pack: PackageData | MinimalPackageData): pack is PackageData => {
    if (!isComplexThemeType(pack.themeLb)) return false;
    if (!pack.themeLb.includes('Batterie')) return false;
    return categorizedIsBattery(pack.mainProduct);
};
export const isBattery = (candidate: SomethingThatCanBeBattery): boolean => {
    if (isCategorized(candidate)) return categorizedIsBattery(candidate);

    if (isMinimalPackageData(candidate)) return packIsBattery(candidate);

    if (Array.isArray(candidate.produits)) {
        for (const p of candidate.produits) {
            if (isCategorized(p) && categorizedIsBattery(p)) return true;
        }
    }
    return false;
};

const categorizedIsPV = (productCandidate: Categorized): boolean => {
    return (
        isKnownRootCategories(productCandidate.categorie_racine) &&
        productCandidate.categorie_racine === 'Photovoltaïque' &&
        isKnownParentCategories(productCandidate.categorie_parent) &&
        productCandidate.categorie_parent === 'Panneau' &&
        isKnownCategories(productCandidate.categorie) &&
        productCandidate.categorie !== 'Batterie'
    );
};

const categorizedIsBattery = (productCandidate: Categorized): boolean => {
    return (
        isKnownRootCategories(productCandidate.categorie_racine) &&
        productCandidate.categorie_racine === 'Photovoltaïque' &&
        // isKnownParentCategories(productCandidate.categorie_parent) &&
        // productCandidate.categorie_parent === 'Photovoltaïque' &&
        isKnownCategories(productCandidate.categorie) &&
        productCandidate.categorie === 'Batterie'
    );
};

const categorizedIsMMA = (productCandidate: Categorized): boolean => {
    return (
        isKnownRootCategories(productCandidate.categorie_racine) &&
        productCandidate.categorie_racine === 'Services' &&
        isKnownParentCategories(productCandidate.categorie_parent) &&
        productCandidate.categorie_parent === 'Services' &&
        isKnownCategories(productCandidate.categorie) &&
        productCandidate.categorie === 'Assurance'
    );
};

/**
 * mettre a jour un package avec une aide.
 * @param pack le pack a mettre à jour
 * @param help l'aide qui DOIT être négative
 */
export const updatePackagePrice = (pack: PackageData, help?: number): void => {
    pack.priceHt = pack.mainProduct.quantite * pack.mainProduct.prix_ht;
    pack.priceTtc = pack.mainProduct.quantite * pack.mainProduct.prix_ht * (1 + pack.mainProduct.tva / 100.0);
    if (pack.poseProduct) {
        pack.priceHt += pack.poseProduct.quantite * pack.poseProduct.prix_ht;
        pack.priceTtc += pack.poseProduct.quantite * pack.poseProduct.prix_ht * (1 + pack.poseProduct.tva / 100.0);
    }

    pack.totalHelp = help ?? 0;
    pack.totalHelpDeduced = pack.priceTtc + pack.totalHelp;
};

// icoll web service for get all package respond with some type that could be wirtten like this :
type icollProductOutput = { produits: Array<{ [key: string]: string }> };
export type icollPackageOutput = { [key: string]: string } & icollProductOutput;

const PackageSelectModeName = ['Multiple', 'ZeroOrOne'] as const;
export type PackageSelectMode = (typeof PackageSelectModeName)[number];

/**
 * here are the selection mode for all themes !
 * this is hard coded.
 */
export const packageSelectionMode: { [key in SubThemeType]: PackageSelectMode } = {
    Chauffage: 'Multiple',
    'Eau chaude sanitaire': 'ZeroOrOne',
    'Changement comportemental': 'Multiple',
    Isolation: 'Multiple',
    Photovoltaïque: 'ZeroOrOne',
    Batterie: 'ZeroOrOne',
    Ventilation: 'ZeroOrOne',

    'Porte et fenêtre': 'Multiple', // TODO

    Veranda: 'Multiple',
    Pergola: 'Multiple',
    Carport: 'Multiple',
    'Ma piéce en plus': 'Multiple',
    'Nettoyage-refection': 'Multiple',
    "Mon contrat d'énergie": 'Multiple',
    'Assurance habitation': 'ZeroOrOne',
    'Alarme et sécurité': 'Multiple',
    'Voiture électrique': 'Multiple',
    'Moto électrique': 'Multiple',
    'Borne de recharge': 'Multiple',
    Piscine: 'ZeroOrOne',
    Portail: 'Multiple',
    'Eclairage exterieur': 'Multiple',
    'Garantie de revenu solaire': 'ZeroOrOne',
};
