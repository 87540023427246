/**
 * Liste des types de plafonds
 */
export const ceilingTypeList: { label: string; value: string; buildingType?: string[] }[] = [
    {
        label: 'Combles non aménagés',
        value: '0',
        buildingType: ['1', '2', '3', '4'],
    },
    {
        label: 'Combles aménagés',
        value: '2',
        buildingType: ['1', '2', '3', '4'],
    },
    {
        label: 'Toiture terrasse',
        value: '3',
        buildingType: ['1', '2', '3', '4'],
    },
    {
        label: 'Plafond mitoyen (autre logement)',
        value: '4',
        buildingType: ['2'],
    },
];

/**
 * Liste des types de composition de plafonds
 */
export const ceilingCompositionList: { label: string; value: string }[] = [
    {
        label: 'Inconnu',
        value: '0',
    },
    {
        label: 'Plafond bois',
        value: '2',
    },
    {
        label: 'Sur solives bois',
        value: '3',
    },
    {
        label: 'Entre solives bois',
        value: '4',
    },
    {
        label: 'Sur solives métalliques',
        value: '5',
    },
    {
        label: 'Sous solives métalliques',
        value: '6',
    },
    {
        label: 'Entre solives métalliques',
        value: '7',
    },
    {
        label: 'Entrevous/poutrelles béton',
        value: '8',
    },
    {
        label: 'Dalle béton',
        value: '9',
    },
    {
        label: 'Rampants (combles aménagés)',
        value: '10',
    },
    {
        label: 'Bac collaborant',
        value: '11',
    },
    {
        label: 'Bac acier double peau',
        value: '12',
    },
    {
        label: 'Plafond léger (plaque de plâtre)',
        value: '13',
    },
];

/**
 * Liste des types de planchers
 */
export const floorTypeList: { label: string; value: string; buildingType?: string[] }[] = [
    {
        label: 'Plancher sur Terre plein',
        value: '0',
        buildingType: ['1', '2', '3', '4'],
    },
    {
        label: 'Plancher sur vide sanitaire',
        value: '2',
        buildingType: ['1', '2', '3', '4'],
    },
    {
        label: 'Plancher sur sous sol',
        value: '3',
        buildingType: ['1', '2', '3', '4'],
    },
    {
        label: 'Plancher mitoyen (autre logement)',
        value: '4',
        buildingType: ['2'],
    },
];

/**
 * Liste des types de composition de planchers
 */
export const floorCompositionList: { label: string; value: string }[] = [
    {
        label: 'Inconnu',
        value: '0',
    },
    {
        label: 'Dalle béton',
        value: '2',
    },
    {
        label: 'Plancher lourd entrevous, poutrelles béton',
        value: '3',
    },
    {
        label: 'Bac collaborant',
        value: '4',
    },
    {
        label: 'Voutain brique/pierre',
        value: '5',
    },
    {
        label: 'Plancher solives bois',
        value: '6',
    },
    {
        label: 'Plancher bois sur solives bois',
        value: '7',
    },
    {
        label: 'Plancher bois sur solives métalliques',
        value: '8',
    },
];

/**
 * Liste des types de composition de murs extérieurs
 */
export const exteriorWallCompositionList: { label: string; value: string }[] = [
    {
        label: 'Inconnu',
        value: '0',
    },
    {
        label: 'Mur en pans de bois',
        value: '1',
    },
    {
        label: 'Mur double brique pleine',
        value: '2',
    },
    {
        label: 'Mur béton banché',
        value: '3',
    },
    {
        label: 'Mur en pierre',
        value: '4',
    },
    {
        label: 'Mur en bois',
        value: '5',
    },
    {
        label: 'Mur en brique creuse',
        value: '6',
    },
    {
        label: 'Mono mur',
        value: '7',
    },
    {
        label: 'Mur parpaing creux',
        value: '8',
    },
    {
        label: 'Mur brique pleine',
        value: '9',
    },
    {
        label: 'Mur bloc béton plein',
        value: '10',
    },
    {
        label: 'Béton cellulaire',
        value: '11',
    },
    {
        label: 'Cloison de plâtre',
        value: '12',
    },
    {
        label: 'Murs en pisé, terre crue ou torchis',
        value: '13',
    },
];

/**
 * Liste des types de composition de murs sur local non-chauffé
 */
export const unheatedRoomCompositionList: { label: string; value: string }[] = [
    {
        label: 'Inconnu',
        value: '0',
    },
    {
        label: 'Mur en pans de bois',
        value: '1',
    },
    {
        label: 'Mur double brique pleine',
        value: '2',
    },
    {
        label: 'Mur béton banché',
        value: '3',
    },
    {
        label: 'Mur en pierre',
        value: '4',
    },
    {
        label: 'Mur en bois',
        value: '5',
    },
    {
        label: 'Mur en brique creuse',
        value: '6',
    },
    {
        label: 'Mono mur',
        value: '7',
    },
    {
        label: 'Mur parpaing creux',
        value: '8',
    },
    {
        label: 'Mur brique pleine',
        value: '9',
    },
    {
        label: 'Mur bloc béton plein',
        value: '10',
    },
    {
        label: 'Béton cellulaire',
        value: '11',
    },
    {
        label: 'Cloison de plâtre',
        value: '12',
    },
];
