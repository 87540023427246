import { Path } from '../HomeInformationsEnums';
// import { googleApiKey } from 'envHelper';
import { BBox } from 'geojson';

// Constants for the map generation in step 3
export const WIDTH = 640;
export const HEIGHT = 480;

export const containerStyle = {
    width: '100%',
    height: '480px',
};

const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Helper function to calculate BBOX for a tile
export const tileCoordsToBBox = (map: google.maps.Map | null, coord: google.maps.Point, zoom: number, tileSize: number) => {
    if (!map) {
        console.warn('Map not available');
        return;
    }
    const proj = map.getProjection();

    if (!proj) {
        console.warn('Projection not available');
        return;
    }

    // Scale is because the number of tiles shown at each zoom level double.
    const scale = Math.pow(2, zoom);

    // Calculate the north-east and south-west corners
    const ne = proj.fromPointToLatLng(new google.maps.Point(((coord.x + 1) * tileSize) / scale, (coord.y * tileSize) / scale)) as google.maps.LatLng;
    const sw = proj.fromPointToLatLng(new google.maps.Point((coord.x * tileSize) / scale, ((coord.y + 1) * tileSize) / scale)) as google.maps.LatLng;

    return [sw.lat(), sw.lng(), ne.lat(), ne.lng()].join(',');
};

// Custom map type ids
export const ECustomMapTypeIds = {
    Inspire: 'Inspire',
    Ign: 'Ign',
} as const;

export type CustomMapTypeId = (typeof ECustomMapTypeIds)[keyof typeof ECustomMapTypeIds];

// Custom map options
export const mapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    zoomControl: true,
    rotateControl: false,
    scaleControl: true,
    styles: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
        },
    ],
    tilt: 0,
    maxZoom: 20,
    mapTypeControlOptions: {
        mapTypeIds: [ECustomMapTypeIds.Inspire, ECustomMapTypeIds.Ign, google.maps.MapTypeId.SATELLITE],
    },
    mapTypeId: google.maps.MapTypeId.SATELLITE,
};

// Polygon options
export const polygonOptions: google.maps.PolygonOptions = {
    fillColor: '#000',
    fillOpacity: 0.7,
    strokeColor: '#000',
    strokeOpacity: 1,
    strokeWeight: 1,
    draggable: true,
    geodesic: false,
    editable: true,
    clickable: true,
    zIndex: 1,
};

// Address polygon options
export const addressPolygonOptions: google.maps.PolygonOptions = {
    fillColor: '#f00',
    fillOpacity: 0.5,
    strokeColor: '#f00',
    strokeOpacity: 1,
    strokeWeight: 1,
    draggable: false,
    editable: false,
    geodesic: false,
};

// Drawing options
export const drawingOptions: google.maps.drawing.DrawingManagerOptions = {
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions,
};

// Helper function to build a static map picture URL
export const buildStaticMapPictureUrl = (
    center: google.maps.LatLng,
    zoom: number,
    width: number,
    height: number,
    maptype = 'satellite',
    address?: Path,
    polygons?: Path[]
) => {
    // URL of Google Static Maps
    let staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap';

    // Set the Google Map Center
    staticMapUrl += `?center=${center.lat()},${center.lng()}`;

    // Set the Google Map Api key
    staticMapUrl += `&key=${googleApiKey}`;

    // Set the Google Map Size
    staticMapUrl += `&size=${width}x${height}`;

    // Set the Google Map Zoom
    staticMapUrl += `&zoom=${zoom}`;

    // Set the Google Map Type
    staticMapUrl += `&maptype=${maptype}`;

    if (address) {
        staticMapUrl += '&path=fillcolor:0xFF000080|weight:0';
        address.forEach((path) => (staticMapUrl += '|' + path.lat + ',' + path.lng));
    }

    if (polygons) {
        polygons.forEach((polygon) => {
            staticMapUrl += '&path=fillcolor:0x000000B3|weight:0';
            polygon.forEach((path) => (staticMapUrl += '|' + path.lat + ',' + path.lng));
        });
    }

    return staticMapUrl;
};

// Helper function to get the BBOX from the bounds of the map
export const getBboxFromBounds = (map: google.maps.Map): BBox => {
    // Get the bounds from the map
    const bounds = map.getBounds();

    // Extract southwest and northeast corners
    const southWest = bounds!.getSouthWest();
    const northEast = bounds!.getNorthEast();

    // Create the Bbox array [minLng, minLat, maxLng, maxLat]
    const bbox: BBox = [
        Number(southWest.lat().toFixed(6)), // minLat
        Number(southWest.lng().toFixed(6)), // minLng
        Number(northEast.lat().toFixed(6)), // maxLat
        Number(northEast.lng().toFixed(6)), // maxLng
    ];

    return bbox;
};

// Helper function to get the BBOX from the URL
export const getBBoxFromURL = (url: string): BBox => {
    // Parse the URL
    const urlObj = new URL(url);

    // Extract the 'bbox' parameter
    const bbox = urlObj.searchParams.get('bbox');

    // If bbox exists, split it into an array of numbers
    if (bbox) {
        const bboxArray = bbox.split(',').map(Number);
        if (bboxArray.length === 4 || bboxArray.length === 6) {
            return bboxArray as BBox;
        } else {
            throw new Error('Invalid number of elements in bbox parameter');
        }
    } else {
        throw new Error('bbox parameter not found in the URL');
    }
};

// Constants
const EARTH_CIRCUMFERENCE_METERS = 40075017;
const TILE_SIZE = 256;
const PIXELS_PER_CM = 37.7952755906;

// Calculate the scale of the map
export const calculateScale = (zoomLevel: number): number => {
    // Calculate meters per pixel
    const metersPerPixel = EARTH_CIRCUMFERENCE_METERS / (TILE_SIZE * Math.pow(2, zoomLevel));

    // Convert meters per pixel to centimeters per pixel
    const cmPerPixel = metersPerPixel * 100;

    // Calculate how many real-life centimeters correspond to 1 cm on the map
    const realLifeCmPerMapCm = PIXELS_PER_CM * cmPerPixel;
    return realLifeCmPerMapCm;
};
