import React from 'react';
import { PaginationState } from '@tanstack/react-table';
import { ReactComponent as IconArrow } from '../../assets/icons/espace-agent/icon-arrow.svg';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    pagination: PaginationState;
    canPreviousPage: boolean;
    canNextPage: boolean;
    onPaginationChange: (paginationState: PaginationState) => void;
    pageSize: number;
    onPageSizeChange: (newPageSize: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    pagination,
    canPreviousPage,
    canNextPage,
    onPaginationChange,
    pageSize,
    onPageSizeChange,
}) => {
    return (
        <div className="table-pagination d-flex align-items-center justify-content-end">
            <div>Élements par page :</div>
            <div className="pagination-select">
                <select
                    value={pageSize}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onPageSizeChange(Number(e.target.value));
                    }}
                >
                    {[10, 20, 40].map((size: number) => (
                        <option key={size} value={size}>
                            {size}
                        </option>
                    ))}
                </select>
            </div>
            <button
                type="button"
                className="btn-previous"
                onClick={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex - 1 })}
                disabled={!canPreviousPage}
            >
                <IconArrow fill="grey" />
            </button>
            <div>
                {currentPage + 1} / {totalPages}
            </div>
            <button
                type="button"
                className="btn-next"
                onClick={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex + 1 })}
                disabled={!canNextPage}
            >
                <IconArrow fill="grey" />
            </button>
        </div>
    );
};
