import { filterMethod } from '../filterPreconisation';
import { SubTheme } from '../theme';

export const filterPorteEtFenetre: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    // loop on all package, and select everyhing

    if (subTheme.packages.length === 0) {
        return subTheme;
    }

    const porteSecondaire = audit.buildingDoor2Presence && audit.buildingDoor2Presence.value === true;

    // on prend le premier arbitrairement.
    subTheme.packages[0].applicable = true;

    for (const pack of subTheme.packages) {
        pack.mainProduct.quantite = porteSecondaire ? 2 : 1;
        pack.mainProduct.quantiteRecommandee = porteSecondaire ? 2 : 1;
    }

    return subTheme;
};
