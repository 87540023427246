import * as auditHelper from '../../tools/auditTools';
import { filterMethod } from '../filterPreconisation';
import { PackageData } from '../package';
import { isCategories } from '../products';
import { SubTheme } from '../theme';

export const filterIsolation: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    let packMurs: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && p.mainProduct.categorie === 'Murs';
    });
    let packPlanchers: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && p.mainProduct.categorie === 'Planchers';
    });
    let packComble: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && p.mainProduct.categorie === 'Combles';
    });
    let packRampants: Array<PackageData> = subTheme.packages.filter((p) => {
        return isCategories(p.mainProduct.categorie) && p.mainProduct.categorie === 'Rampants';
    });

    packMurs = filterIsolationMurs(audit, packMurs);
    packPlanchers = filterIsolationPlancher(audit, packPlanchers);
    packComble = filterIsolationComble(audit, packComble);
    packRampants = filterIsolationRampants(audit, packRampants);

    const result = new Array<PackageData>();
    result.push(...packMurs);
    result.push(...packPlanchers);
    result.push(...packComble);
    result.push(...packRampants);
    subTheme.packages = result;

    return subTheme;
};

// Quand comble "non aménagé" => le package comble et le package rampant . Le package sélectionné par defaut = comble
// Quand comble “aménagé" => que le package rampant

/**
 * filtrer les packages inutiles du theme 'isolation des comble'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param theme le theme dont le type doit être isolation des comble. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
const filterIsolationComble = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    // on a del'isolation des combles si le type de ceiling est comble non aménagés !
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;

    //#region ajout du 2024-09-16 par RTA, non validé explicitement.
    // Si les combles sont non aménagés,
    // on propose l'isolation des combles.

    // autrement dit : Si les combles ne sont pas "non aménagés", c'est le même cas.
    const comblesNonAmenaged = audit.ceilingType !== undefined && audit.ceilingType.value === '0';
    if (!comblesNonAmenaged)
        // combles non aménagés => faut que s'occuper des rampants.
        // ou voisin au dessus
        return emptyResult;

    //#endregion

    // La règle :
    // si ( pas d'isolation des combles  OU  isolation < 20cm )
    //     on prend tout
    // sinon,
    //     on prend rien

    let preconiser = false;

    // Si on ne sait pas, ou
    // Si pas d'isolation des comble, on prend tout
    if (audit.ceilingInsulationPresence === undefined || audit.ceilingInsulationPresence.value === false) preconiser = true;

    // SI l'epaissuer n'existe pas
    // si l'epaisseur est < 25 cm
    if (audit.ceilingInsulationThickness === undefined || +audit.ceilingInsulationThickness.value < 25) preconiser = true;

    // si l'isolation des comble est trop ancienne > 20 ans, on preconise :
    const currentYear = new Date().getFullYear();
    if (audit.ceilingInsulationAge !== undefined && +audit.ceilingInsulationAge.value < currentYear - 20) preconiser = true;

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on va retourner tous les packages
    // on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfacePlancherComble(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on selecitonne le premier (on a déjà éliminé le cas ou len <= 0)
    packages[0].applicable = true;

    return packages;
};
/**
 * filtrer les packages inutiles du theme 'isolation des murs'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param theme le theme dont le type doit être 'isolation des murs'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
const filterIsolationMurs = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    //console.log('passed in filterisolationMurs');
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;

    // Si les murs sont en bois ou en pisé, on ne propose aps d'isolation des murs.
    //  'Mur en pans de bois',=== '1',
    //  'Mur en bois' === '5',
    //  'Mur en pisé' === '13',
    //console.log('audit.exteriorWallComposition', audit.exteriorWallComposition);
    if (
        audit.exteriorWallComposition !== undefined &&
        (audit.exteriorWallComposition.value === undefined ||
            audit.exteriorWallComposition.value === '1' ||
            audit.exteriorWallComposition.value === '5' ||
            audit.exteriorWallComposition.value === '13')
    ) {
        return emptyResult;
    }

    // La règle :
    // si ( pas isolation des murs  OU  isolation < 10cm )
    //     on prend tout
    // sinon,
    //     on prend rien

    let preconiser = false;

    // si les murs ne sont aps isolés
    if (audit.exteriorWallInsulationPresence === undefined || audit.exteriorWallInsulationPresence.value === false) preconiser = true;

    // SI l'epaissuer n'existe pas ou si l'epaisseur est < 12 cm
    if (audit.exteriorWallInsulationThickness === undefined || +audit.exteriorWallInsulationThickness.value < 12) preconiser = true;

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfaceDesMurs(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on ne selecitonne rien par défaut.

    return packages;
};
/**
 * filtrer les packages inutiles du theme 'isolation des planchers'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param theme le theme dont le type doit être 'isolation des planchers'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
const filterIsolationPlancher = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    //console.log('passed in filterIsolationPlancher');
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;
    // La règle :
    // si ( pas d'isolation des plancher  ET  il existe un sous-sol )
    //     on prend tout
    // sinon,
    //     on prend rien

    let preconiser = false;

    // On utilise la variable floorTypeList du fichier src/pages/envelope-informations/EnvelopeInformationsEnums.ts

    // si On ne connait pas la nature du sol, on préconise
    if (audit.floorType === undefined || audit.floorInsulationPresence === undefined) preconiser = true;

    // Si ni vide sanitaire ni sous-sol
    if (+audit.floorType.value !== 2 && +audit.floorType.value !== 3) {
        preconiser = false;
    } else {
        // SI présence de vide sanitaire et/ou sous-sol
        if (
            // ET que les planchers ne sont pas isolés
            audit.floorInsulationPresence !== undefined &&
            audit.floorInsulationPresence.value === false
        )
            preconiser = true;
    }
    if (!preconiser) {
        //console.log('pas de preconisation pour les planchers');
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfacePlancher(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on selecitonne le premier (on a déjà éliminé le cas ou len <= 0)
    packages[0].applicable = true;

    return packages;
};

//#region ajout du 2024-09-16 par RTA, non validé explicitement.
// Quand comble "non aménagé" => le package comble et le package rampant . Le package sélectionné par defaut = comble
// Quand comble “aménagé" => que le package rampant

const filterIsolationRampants = (audit: any, packages: Array<PackageData>): Array<PackageData> => {
    const emptyResult = new Array<PackageData>();
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (packages.length === 0) return emptyResult;
    // La règle :
    // si ( pas d'isolation des combles  OU  isolation < 20cm )
    //     on prend tout
    // sinon,
    //     on prend rien

    // si toiture terrasse, ou voisin au dessus, isoler les rampants n'a pas de sens !
    if (audit.ceilingType === '3' || audit.ceilingType === '4') return emptyResult;

    let preconiser = false;
    // combles  aménagés => faut que s'occuper des rampants.
    const comblesNonAmenaged = audit.ceilingType !== undefined && audit.ceilingType.value === '0';
    const combleAmenages = audit.ceilingType !== undefined && audit.ceilingType === '2';

    const rampantsIsoles = audit.rampantIsolated && audit.rampantIsolated.value === true;
    // les combles ne sont aps aménagés, on propose els rampants :
    if (combleAmenages && rampantsIsoles) preconiser = false;
    else {
        preconiser = true;
    }
    // => on fera aussi les combles, mais c'est dans le filtrage comble.

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        return emptyResult;
    }

    // Si on doit préconiser, on va retourner tous les packages
    // on ajoute la quantité recommandée à tous les packages du theme :
    const quantiteRecommandee = Math.ceil(auditHelper.getSurfaceRampants(audit));
    for (const pack of packages) {
        pack.mainProduct.quantite = quantiteRecommandee;
        pack.mainProduct.quantiteRecommandee = quantiteRecommandee;
    }

    // on selecitonne le premier (on a déjà éliminé le cas ou len <= 0)
    // par défaut dans le cas ou les combles ne sont pas aménagés,
    //on sélectionne plutot l'isolation des combles.
    packages[0].applicable = !comblesNonAmenaged;

    return packages;
};
//#endregion
