import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, GoogleMapProps, StreetViewPanorama } from '@react-google-maps/api';
import { GeoPosition } from '../../pages/audit-simulator/rendement/Rendement';

// docs https://developers.google.com/maps/documentation/javascript/streetview?hl=fr

export type GooglePov = {
    heading: number;
    pitch: number;
    zoom: number;
};

export type StreetViewSpec = GooglePov & GeoPosition;

const defaultMapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    zoomControl: true,
    rotateControl: false,
    panControl: false,
    scaleControl: true,
    keyboardShortcuts: false,
    styles: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
        },
    ],
    tilt: 0,
    maxZoom: 20,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
};

const defaultPanoramaOptions: google.maps.StreetViewPanoramaOptions = {
    pov: {
        heading: 0,
        pitch: 0,
    },
    zoom: 0,
    visible: true,
    fullscreenControl: false,
    enableCloseButton: false,
    panControl: false,
    addressControl: false,
};

type CustomGoogleMapStreetViewProps = {
    center: GeoPosition;
    streetViewRef: React.MutableRefObject<google.maps.StreetViewPanorama | null>;
    mapRef: React.MutableRefObject<google.maps.Map | null>;
    googleMapProps?: GoogleMapProps;
};

const GoogleMapsStreetView: React.FC<CustomGoogleMapStreetViewProps> = ({ center, googleMapProps, streetViewRef, mapRef }) => {
    const [panoramaOptions, setPanoramaOptions] = useState<google.maps.StreetViewPanoramaOptions>(defaultPanoramaOptions);
    const [isStreetViewVisible, setIsStreetViewVisible] = useState(true);

    // Find nearest street view to the center and calculate heading from the user's position
    useEffect(() => {
        const findNearestStreetView = async () => {
            const streetViewService = new google.maps.StreetViewService();
            let radius = 50;
            let found = false;

            while (!found && radius <= 5000) {
                // Increase radius up to 5000 meters
                await new Promise<void>((resolve) => {
                    streetViewService.getPanorama({ location: center, radius }, (data, status) => {
                        if (status === google.maps.StreetViewStatus.OK && data?.location && data.location.latLng) {
                            const targetLatLng = new google.maps.LatLng(center.lat, center.lng);
                            const panoramaLatLng = data.location.latLng;
                            const heading = google.maps.geometry.spherical.computeHeading(panoramaLatLng, targetLatLng);

                            setPanoramaOptions((prev) => ({
                                ...prev,
                                pov: {
                                    heading,
                                    pitch: 0,
                                },
                                position: { lat: panoramaLatLng.lat(), lng: panoramaLatLng.lng() },
                            }));

                            found = true;
                        }
                        resolve();
                    });
                });
                radius *= 2;
            }
        };

        findNearestStreetView();
    }, [center]);

    // set ref to map
    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            mapRef.current = map;
        },
        [mapRef]
    );

    // set ref to street view
    const onStreetViewPanoramaLoad = useCallback(
        (streetViewPanorama: google.maps.StreetViewPanorama) => {
            streetViewRef.current = streetViewPanorama;
        },
        [streetViewRef]
    );

    // Switch to street view
    const switchToStreetView = useCallback(() => {
        setIsStreetViewVisible(true);
    }, []);

    // Switch to map view
    const switchToMap = useCallback(() => {
        setIsStreetViewVisible(false);

        if (isStreetViewVisible && mapRef.current) {
            // If switching to Map View, reset the center
            mapRef.current.setCenter(center);
        }
    }, [center, isStreetViewVisible, mapRef]);

    return (
        <div className="google-maps-streetview">
            <GoogleMap {...googleMapProps} options={{ ...defaultMapOptions, mapTypeControl: false }} center={center} onLoad={onMapLoad}>
                <div className="google-maps-streetview-switch">
                    <button
                        onClick={switchToStreetView}
                        className={`google-maps-streetview-switch-btn ${isStreetViewVisible ? 'google-maps-streetview-switch-btn--active' : ''}`}
                    >
                        Street View
                    </button>
                    <button
                        onClick={switchToMap}
                        className={`google-maps-streetview-switch-btn ${isStreetViewVisible ? '' : 'google-maps-streetview-switch-btn--active'}`}
                    >
                        Satellite
                    </button>
                </div>
                {isStreetViewVisible && <StreetViewPanorama onLoad={onStreetViewPanoramaLoad} options={{ ...defaultPanoramaOptions, ...panoramaOptions }} />}
            </GoogleMap>
        </div>
    );
};

export default React.memo(GoogleMapsStreetView);
