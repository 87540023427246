import { filterMethod } from '../filterPreconisation';
import { SubTheme } from '../theme';

// #region filter changement comportemental
/**
 * filtrer les packages inutiles du theme 'ChangementComportemental'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param subTheme le theme dont le type doit être 'ChangementComportemental'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
export const filterChangementComportemental: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    //console.log('passed in filterChangementComportemental');
    // On affiche tout, dans tous les cas.
    // Donc aucun filtre
    // Et on selectionne tout.
    if (subTheme.packages.length === 0) return subTheme;

    for (const pack of subTheme.packages) {
        pack.applicable = true;
        pack.mainProduct.quantite = 1;
        pack.mainProduct.quantiteRecommandee = undefined;
    }

    return subTheme;
};

// #endregion
