import { PackageData } from '../package';
import { isKnownCategories } from '../products';
import { SubTheme } from '../theme';

// Read me
//
// ACCRONYMES :
// PAC = pompe a chaleur
// AA = air/air
// AE = Air/eau
//
// WARNING :
// Dans ce fichier on trouve quelques fonctions qui permettent de filtrer les PAC selon un critère,
// exemple : keepPacPec et removePacPec.
// ATTENTION removePacPec n'est pas égal a !keepPacPec
// il en va de même pour toutes les fonctions.

//#region  les petites fonctions keep et remove, et les regex.

// quelques chaines et regex pour le filtrage et le tri des packages de pompes à chaleur.
// [0-9.,]+ prend aussi les PAC 4.5kw
// Les packages basse température ont -BT- dans leur reférence.
const PAC_BASSE_TEMP_MIDFIX = '-BT-';
// Les pompes à chaleurs qui permettent aussi la gestion de l'eau chaude, pour rempalcer les chaudière avec 'eau chaude liée au chauffage'
const PAC_PEC_MIDFIX = '-PEC-';
// regex pour trouver les PAC AE basse temperature
const PAC_AIR_EAU_BASSE_TEMPERATURE_REGEX = /^PAC-AE-([0-9.,]+)-BT-/;
// regex pour trouver les PAC AE moyenne et haute température (ou non basse temperature)
const PAC_AIR_EAU_MOYENNE_HAUTE_TEMPERATURE_REGEX = /^(?!.*-BT-)PAC-AE-([0-9.,]+)-(MT-)?/; // (?!.*-BT-) signifie sauf si il y a -BT- quelque part.
// regex pour trouver la puissance, ou nombre de plit
export const PAC_SPLIT_REGEX = /^PAC-A(A|E)-([0-9.,]+)-/;
// les pompes a chaleurs qui permettent de gérer deux réseaux d'eau (exemple, plancher chauffant + radiateur à un autre étage.)
const PAC_MULTIPLE_HYDRONIC_ZONES_MIDFIX = '-2R-'; // pour 2 Reseaux
// gestion des types de courant mono ou tri phasés.
const PAC_MONOPHASE = '-M-'; // Mono phasée
const PAC_MONOPHASE_END = '-M'; // Mono phasée
const PAC_TRIPHASE = '-T-'; // triphasée
const PAC_TRIPHASE_END = '-T'; // triphasée
export const PAC_AA_START = 'PAC-AA';
export const PAC_AE_START = 'PAC-AE';
//

export const keepPacAEBasseTemp = (pack: PackageData): boolean => {
    // PLAN A : normalement cette règle fait tout. on cherche la regex qui dit PAC A/E et -BT- ...
    if (pack.reference) {
        const match = pack.reference.match(PAC_AIR_EAU_BASSE_TEMPERATURE_REGEX);
        if (match && match.length >= 1) {
            return true;
        }
    }

    // Si la regex suffit pas, CE QUI NE DEVRAIT PAS ARRIVER.
    // supprimer à partir de là à l'avenir.

    // On passe au plans B, C et D
    // plan B selon la catégorie de produit et si la réference contient "-BT-"
    if (pack.mainProduct.categorie !== 'Air/Eau' && pack.mainProduct.categorie !== 'Pac Air Eau') return false;
    if (pack.reference.includes(PAC_BASSE_TEMP_MIDFIX)) {
        console.log('isPacAEBasseTemp => ref NOT match => BUT categorie + -BT-');
        return true;
    }

    // ancienne méthode, aléatoire sur le nom de package.
    // Laissé pour la préprod
    // plan B seleon la catégorie de produit et si la réference contient "-BT-"
    if (pack.mainProduct.nom.includes('Basse Temp')) {
        console.log("isPacAEBasseTemp => ref NOT match => BUT 'Basse Temp' in title");
        return true;
    }

    return false;
};

export const keepPacAEMoyenneHauteTemp = (pack: PackageData): boolean => {
    // PLAN A : normalement cette règle fait tout. on cherche la regex qui dit PAC A/E et -MT- sans -BT- ...
    if (pack.reference) {
        const match = pack.reference.match(PAC_AIR_EAU_MOYENNE_HAUTE_TEMPERATURE_REGEX);
        if (match && match.length >= 1) {
            return true;
        }
    }

    // Si la regex suffit pas, CE QUI NE DEVRAIT PAS ARRIVER.
    // supprimer à partir de là à l'avenir.

    // On tentes les autres approches :
    if (pack.mainProduct.categorie !== 'Air/Eau' && pack.mainProduct.categorie !== 'Pac Air Eau') return false;
    if (pack.reference.includes(PAC_BASSE_TEMP_MIDFIX)) return false;

    // aléatoire
    // Laissé pour la préprod
    if (pack.mainProduct.nom.includes('Basse Temp') || pack.mainProduct.nom.includes('60°C')) return false;

    console.log('isPacAEMoyenneHauteTemp => ref NOT match => BUT ok any way for ref ' + pack.reference);
    return true;
};
export const removePacAEBasseTemp = (pack: PackageData): boolean => {
    // doit retourner true pour garder, false pour supprimer.
    return !keepPacAEBasseTemp(pack);
};
export const removePacAEMoyenneHauteTemp = (pack: PackageData): boolean => {
    // doit retourner true pour garder, false pour supprimer.
    return !keepPacAEMoyenneHauteTemp(pack);
};
export const keepPacPec = (pack: PackageData): boolean => {
    if (!pack.reference) return false;
    return pack.reference.includes(PAC_PEC_MIDFIX);
};
export const removePacPec = (pack: PackageData): boolean => {
    if (!pack.reference) return false;
    return !pack.reference.includes(PAC_PEC_MIDFIX);
};
export const keepPac2R = (pack: PackageData): boolean => {
    if (!pack.reference) return false;
    return pack.reference.includes(PAC_MULTIPLE_HYDRONIC_ZONES_MIDFIX);
};
export const removePac2R = (pack: PackageData): boolean => {
    if (!pack.reference) return false;
    return !pack.reference.includes(PAC_MULTIPLE_HYDRONIC_ZONES_MIDFIX);
};

// pour le tri phasé, mono phasé,
// certaines PAC présentent les deux variations, dans ce cas l'indicateur -T- -T -M- ou -M est présent.
// si l'indicateur n'est pas présent, c'est du mono.
export const keepPacMonophase = (pack: PackageData): boolean => {
    if (!pack.reference) return false;
    if (pack.reference.includes(PAC_MONOPHASE) || pack.reference.endsWith(PAC_MONOPHASE_END)) return true;
    return !pack.reference.includes(PAC_TRIPHASE) && !pack.reference.endsWith(PAC_TRIPHASE_END); // true si pas tri phasé.
};
export const keepPacTriphase = (pack: PackageData): boolean => {
    if (!pack.reference) return false;
    return pack.reference.includes(PAC_TRIPHASE) || pack.reference.endsWith(PAC_TRIPHASE_END);
};

//#endregion

//#region les petites fonction de tri des PAC.

export const isPackAE = (pack: PackageData): boolean => {
    return pack.reference.startsWith(PAC_AE_START) || (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Pac Air Eau');
};

export const isPackAA = (pack: PackageData): boolean => {
    return pack.reference.startsWith(PAC_AA_START) || (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Pac Air Air');
};

/**
 * Séparer les packages du theme en 2 parties : PacAA et PacAE
 * @param subTheme
 * @returns un objet composé des deux listes.
 */
export const dispatchPacByType = (subTheme: SubTheme): { pacsAA: Array<PackageData>; pacsAE: Array<PackageData> } => {
    const pacAE = Array<PackageData>();
    const pacAA = Array<PackageData>();

    for (const pack of subTheme.packages) {
        if (isPackAA(pack)) pacAA.push(pack);
        if (isPackAE(pack)) pacAE.push(pack);
    }

    return { pacsAA: pacAA, pacsAE: pacAE };
};

export const extractNbSplit = (pack: PackageData): number => {
    if (pack.reference) {
        const matchAA = pack.reference.match(PAC_SPLIT_REGEX); // = /^PAC-A(A|E)-([0-9.,]+)-/;
        if (matchAA && matchAA.length >= 2) {
            const nbsplit = matchAA[2]; // match[0] toute la chaine, match[1] premier grope de parentheèse (A|E) match[2] le nombre de split
            return +nbsplit;
        }
    }

    // le nom du main product contient "XX Splits"
    let result = +pack.mainProduct.nom.replace(/^.*[^0-9]([0-9]+) SPLITS.*$/i, '$1');
    if (!Number.isNaN(result)) return result;

    // le nom du package contient "XX Splits"
    result = +pack.title.replace(/^.*[^0-9]([0-9]+) SPLITS.*$/i, '$1');
    if (!Number.isNaN(result)) return result;

    // le nom du main product contient "CHAPPEE (XX)"
    result = +pack.mainProduct.nom.replace(/^.*CHAPPEE ?\(([0-9]+)\).*$/i, '$1');
    if (!Number.isNaN(result)) return result;

    return 0;
};

export const compareBySplit = (A: PackageData, B: PackageData): number => {
    const splitA = extractNbSplit(A);
    const splitB = extractNbSplit(B);
    return splitA - splitB;
};

//#endregion

export const extractOtherData = (
    audit: any
):
    | { isTriphase: boolean; isPec: boolean; isMultipleHydronicZone: boolean; heater: string; heaterName: string; surfaceHab: number; year: number }
    | undefined => {
    console.log('extractOtherData audit 1');
    console.log(JSON.stringify({ electricMeter: audit.electricMeter, shab: audit.SHab }, null, 2));
    if (!audit || !audit.electricMeter || !audit.electricMeter.value_label || !audit.SHab) return undefined;
    console.log('extractOtherData audit 2');
    if (!audit.heaterHotWaterIncluded || !audit.multipleHydronicZoneHeatingSystem) return undefined;
    console.log('extractOtherData audit 3');
    if (!audit.heaterFeature) return undefined;
    console.log('extractOtherData audit 4');
    // 3 type de comtpeur electrique : tri mono, ou inconnu.
    // dans le cas inconnu, on admet que c'est monophasé.
    const isTriphase: boolean = audit.electricMeter.value_label === 'Triphasé';
    const isPec: boolean = audit.heaterHotWaterIncluded?.value === true; // Comparer avec true (c'est important). ne pas mettre directement = audit.heaterHotWaterIncluded.value, qui sera faux, si heaterHotWaterIncluded est undefeined
    const isMultipleHydronicZone: boolean = audit.multipleHydronicZoneHeatingSystem?.value === true; //Comparer avec true (c'est important). ne pas mettre directement = audit.multipleHydronicZoneHeatingSystem.value, qui sera faux, si multipleHydronicZoneHeatingSystem est undefeined
    const heater: string = audit.heaterFeature.value;
    const heaterName: string = audit.heaterFeature.value_label;
    const surfaceHab = +audit.SHab.value;
    const year = +audit.houseAge.value;

    return { isTriphase, isPec, isMultipleHydronicZone, heater, heaterName, surfaceHab, year };
};
